<!--
@Author: Calvin Green <calvin>
@Date:   2021-12-14T11:24:47+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-16T08:05:23+02:00
@Copyright: Diversitude 2021
-->

<template>
    <div class="user_container">
        <div class="user_header d-flex col-12 mt-1" style="height: 35px !important; width: 680px;">
            <div class="col-1" style="width: 60px;">
                <b-img
                fluid
                :src="'https://stratosfia.s3.af-south-1.amazonaws.com/avatars/hGeRn0P1wlkTOCWADOHJlJ9KnWzbhsRFfmSSJSZO.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATXC3MMMHAZEXOWO6%2F20240718%2Faf-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240718T090929Z&X-Amz-SignedHeaders=host&X-Amz-Expires=600&X-Amz-Signature=a87550c2ec9d04f58407b0023f049a0ad4ac374260a4be6098d62c10b209ae4f'"
                alt
                onerror="src='/img/user.png'"
                class=" smaller-image-height avatar-md rounded-circle img-thumbnail header-profile-user-view imgStyles"
                id="image"
                style="width: 30px; height: 30px"
                >
                </b-img>
            </div>
            <div class="col-2 d-flex mt-1" style="color: black; font-size: 16px; color: #6E84A7;">
                NAME TEST
            </div>

            <div class="d-flex col-9" style="flex-direction: column-reverse;">
                <span class="btn-group m-1">
                    <span class="w-100" style="width: 60% !important">
                        <b-form-select class="role_select" v-model="selected_role" :options="roles"></b-form-select>
                    </span>
                </span>
            </div>
        </div>
        <div class="body ms-4 ps-4" style="margin-left: 40px !important; margin-top: 5px !important; padding-top: 5px !important">
            <span class="d-flex">
                <b-col class="col-4 d-flex" style="flex-direction: column;">
                    <span class="purple_light">
                        Apollo Dates
                    </span>
                    <span class="mt-2 purple_dark">
                        Start: {{ userData[0].start }}
                    </span>
                    <span class="mt-2 purple_dark">
                        End: {{ userData[0].end }}
                    </span>
                    
                </b-col>
                <b-col class="col-4 d-flex" style="flex-direction: column;">
                    <span class="purple_light">
                        Role Dates
                    </span>
                    <span class="mt-2 purple_dark">
                        Start: {{ userData[0].start }}
                    </span>
                    <span class="mt-2 purple_dark">
                        End: {{ userData[0].end }}
                    </span>
                    
                </b-col>
                <b-col class="col-4 d-flex" style="flex-direction: column;">
                    <span class="purple_light">
                        Role Specific
                    </span>
                    <span class="mt-2 purple_dark">
                        Division: {{userData[0].roles[0].division }}
                    </span>
                    <span class="mt-2 purple_dark">
                        Reporting to: {{userData[0].roles[0].manager }}
                    </span>
                </b-col>
            </span>
           
        </div>
        <div class="body m-4 p-4" style="margin-left: 40px !important; margin-top: 5px !important; padding-top: 5px !important">
            <span class="d-flex">
                <b-col class="col-12" style="display:inline-flex; height: 30px">
                    <span class="m-2 p-2 pastel_yellow_fill d-flex" style="border-radius: 5px; height: 30px; justify-content: center; align-items: center;">
                        <span><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style=""></span><span class="ms-1">In Progress</span>
                    </span>
                    <span class="mt-2">
                        <span>
                            <b-button size="sm" variant="info" style="height: 30px;">Submit Scorecard</b-button>
                        </span>
                    </span>
                </b-col>
            </span>
           
        </div>
        <div class="user_footer d-flex col-12 mt-1" style="background-color: #34C38F; height: 52px !important; font-size: 1.4rem; justify-content: end; align-items: center;">
            <div class="m-2 me-4">
                <span>
                Final Score
                </span>
                <span class="ms-2" style="font-size: 1.8rem !important">
                    {{userData[0].roles[0].score }}
                </span>
            </div>
        </div>
    </div>
</template>

<script> 
//import { menuMethods, authMethods } from '@/state/helpers'
export default {
    name: "userBlock",
    components:{
        
    },
    props: {

    },
    mounted(){
        //this.setLoading(false);
    },
    data: () => ({
         userData: [{id:1, name: "Andrew", avatar: '/img/user.png', start: '17/04/2024', end: ' - ', roles: [{ division: 'Executive Level', manager: 'Nauman', start: '17/04/2024', end: ' - ', score: '9'}] }],
         selected_user: 1,
         selected_role: 1,
         roles: [{value: 1, text: 'Role (2): Freedom Fighter'}],
    }),
    methods:{
        setFilter(value) {
            this.selected_filter = value
        },
        setYearFilter(value) {
            this.selected_year_filter = value
        }
    },

    computed:{
        
    },
}

</script>

<style>
.user_header {
   color: white;
   border-top-left-radius: 18px;
   border-top-right-radius: 18px;
}
.user_footer {
   color: white;
   border-bottom-left-radius: 18px;
   border-bottom-right-radius: 18px;
}
.imgStyles {
   height: 40px;
   width: 40px;
   margin: 3px
}
.user_container {
    width: 680px; 
    max-height: 300px; 
    border: 2px solid #BFC8D6;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.role_select {
    text-align: center; 
    border: 1px solid #6f42c1;  
    border-radius: 15px; color: #6f42c1; 
    font-size: 0.7rem !important;
    height: 1.6rem;
    width: 100%
}
</style>
