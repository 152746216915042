<template>
    <div class="">
        <transition name="slide-fade" mode="out-in">
            <div v-show="showMain" class="">
            <b-card style="height: 500px; width: 100%;" @click="showDetailsComponent('debug')">
                123
            </b-card>
            </div>
        </transition>
        <transition name="slide-fade" mode="out-in">
        
            <div v-show="showDetails" class="">
                <b-card @click="showDetailsComponent(null)" v-if="currentDetailsComponent" class="bg-success bg-soft" style="height: 500px; width: 100%;">
                    <pre>{{ currentDetailsComponent }}</pre>
                </b-card>
            </div>
        </transition>
    </div>
</template>
<script>
  
  export default {
    components: {

    },
    data() {
      return {
        showMain: true,
        showDetails: false,
        currentDetailsComponent: null // Store the component to display in details
      };
    },
    methods: {
      showDetailsComponent(detailsComponent) {
        if(detailsComponent == null){
            this.showMain = true;
            this.showDetails = false;
            this.currentDetailsComponent = detailsComponent; 
        }
        else{
            this.showMain = false;
            this.showDetails = true;
            this.currentDetailsComponent = detailsComponent; 
        }
        
      },
    },
  };
  </script>
  
  <style scoped>
  .app-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden; 
  }
  
  .main-container,
  .details-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  .main-container {
    left: 0;
  }
  
  .details-container {
    right: 0;
  }
  
  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.5s ease;
  }
  
  .slide-fade-enter,
  .slide-fade-leave-to {
    opacity: 0;
  }
  
  .slide-fade-enter {
    transform: translateX(100%);
  }
  
  .slide-fade-leave-to {
    transform: translateX(-100%);
  }
  </style>