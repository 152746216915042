<template>
    <draggable :empty-insert-threshold="8" :list="children" handle=".handle" :group="{name: 'nested'}">
        <b-card v-for="child in children" :key="child.id" class="m-1" :class="getBorder" no-body>
            <div class="d-flex" style="height: 30px;">
                <div v-if="index != 0" class="handle d-flex">
                    <font-awesome-icon class="m-auto" style="width: 25px;" icon="fa-bars"></font-awesome-icon>
                </div>
                <div style="width: 100px;" class="d-flex bg-info bg-soft">
                    <span class="ms-2 my-auto">
                        {{ getItemTitle }}
                    </span>
                </div>
                <div class="d-flex">
                    <span class="ms-2 my-auto">
                        {{ child.name }}
                    </span>
                </div>
                
            </div>
            <nested-list v-if="child.children" :index="index+1" :children="child.children"></nested-list>
            <!--
            animation: 150,  // ms, animation speed moving items when sorting, `0` — without animation
	easing: "cubic-bezier(1, 0, 0, 1)",
    -->

        </b-card>
    </draggable>
</template>

<script>
import draggable from 'vuedraggable'
export default {
    name: 'nested-list',
    components:{
        draggable,
    },
    data:() => ({}),
    props: {
        index:{
            default:()=>{
                return 0;
            }
        },
        children: {
            required: true,
                type: Array
            }
    },
    computed:{
        getItemTitle(){
            switch(this.index){
                case 0:{
                    return 'Service';
                }
                case 1:{
                    return 'Process';
                }
                case 2:{
                    return 'Process Step';
                }
                default:{
                    return 'Procedure';
                }
            }
        },
        getBorder(){
            switch(this.index){
                case 0:{
                    return '';
                }
                case 1:{
                    return 'border-primary';
                }
                case 2:{
                    return 'border-danger';
                }
                default:{
                    return 'border-success';
                }
            }
        }
    },
    methods:{},
    mounted(){},
}
</script>

<style>
.draggable-handle{
    background-color: purple;
}
</style>