<!--
@Author: Calvin Green <calvin>
@Date:   2021-12-14T11:24:47+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-16T08:05:23+02:00
@Copyright: Diversitude 2021
-->

<template>
    <div>
        <div class="d-flex m-1 p-1 container_position">
            <b-col class="d-flex" style="flex-direction: column;">
                <span class="blue_dark main_heading ms-1">
                    Performance Scorecard <span style="display:inline-flex;"><font-awesome-icon style="width:20px" :icon="['fas', 'info-circle']" /></span>
                </span>
                <span style="width: 558px">
                    <p class="text_styling main_text_blue">
                        The performance scorecard shows your data and results for the selected role and period of your employment.  
                        Use your scorecard to keep track of what is required of you within your role. You can make notes on individual items, your manger also has access to your scorecard and may also add notes in preparation for a review.
                    </p>
                </span>
                <!-- <scoreCardFilter /> -->
            </b-col>
            <b-col class="d-flex userCard" style="flex-direction: column; justify-content: end;">
                <scoreCarduserCard />
            </b-col>
        </div>
    </div> 
</template>

<script>
//import { menuMethods, authMethods } from '@/state/helpers'
//import Layout from "../layouts/main";
//import scoreCardFilter from "./scoreCardFilter.vue";
import scoreCarduserCard from "../../components/scorecard/pageComponents/scoreCardUserCard.vue"
export default {
    name: "scoreCardHeader",
    components:{
        //scoreCardFilter,
        scoreCarduserCard
    },
    props: {

    },
    mounted(){
        //this.setLoading(false);
    },
    data: () => ({

    }),
    methods:{
    },

    computed:{
        
    },
}

</script>

<style>
/* .text_styling {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
} */
.container_position {
    margin-top: 15px !important; 
    padding-top: 15px !important;
    margin-bottom: 5px !important; 
    padding-bottom: 5px !important;
    width: 1400px
}
.gradient {
    background-image: linear-gradient(to right, #484F6A , #481886);
    /* background-attachment: fixed */
}

@media (max-width: 1413px) {
    .container_position {
        flex-direction: column;
    }
    .userCard {
        margin-top: 20px;
    }
}


</style>
